<template>
  <LineChart :chart-data="chartData" :options="chartOptions" />
</template>

<script>
import { defineComponent } from "vue";
import { LineChart } from "vue-chart-3";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

export default defineComponent({
  components: {
    LineChart,
  },
  props: {
    eventsData: Array,
  },
  computed: {
    chartData() {
      const labels =
        this.eventsData[0]?.dataPoints.map((dp) => dp.period) || [];
      const datasets = this.eventsData.map((event) => ({
        label: event.eventName,
        data: event.dataPoints.map((dp) => dp.metric),
        borderWidth: 2,
        borderColor: this.stringToColor(event.eventName),
        backgroundColor: this.stringToColor(event.eventName),
        fill: false,
        tension: 0.1,
      }));
      return { labels, datasets };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
            align: "start",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              boxWidth: 10,
              boxHeight: 10,
            },
          },
          title: {
            display: true,
            text: "Event Count by Event Name",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            position: "right",
          },
        },
      };
    },
  },
  methods: {
    stringToColor(str) {
      if (!str) return "#000";
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
      }
      return color;
    },
  },
});
</script>
