
import { Options, Vue } from "vue-class-component";
import LineChartComponent from "@/views/analytics/charts/line-chart.vue";
import BarChartComponent from "@/views/analytics/charts/bar-chart.vue";
import { LeftAdditionalSidebarModule } from "@/store/modules/left-additional-sidebar";
import { Watch } from "vue-property-decorator";
import moment from "moment/moment";
import { PeriodModule } from "@/store/modules/period.module";
import { GoogleAnalyticsModule } from "@/store/modules/google-analytics";

@Options({
  name: "Analytics",
  components: { LineChartComponent, BarChartComponent },
})
export default class EmailLog extends Vue {
  requiredEvents = [
    "click_support_the_sound_explained_pdf",
    "click_answers_to_common_questions",
    "click_introduction_email_text_template",
    "click_logos",
    "page_view_resources",
    "page_view_about_us",
    "page_view_faq",
    "page_view_aslicepresentation",
    "page_view_theaslicefoundation",
    "page_view_contact",
    "page_view_software_download",
  ];

  // COMPUTED
  get list() {
    return GoogleAnalyticsModule.list;
  }

  get filteredList() {
    return GoogleAnalyticsModule.list.filter((item) =>
      this.requiredEvents.includes(item.eventName)
    );
  }
  get fromDateAs() {
    return PeriodModule.fromDate;
  }

  get toDateAs() {
    return PeriodModule.toDate;
  }

  get format() {
    return PeriodModule.format;
  }
  get initiateLoading() {
    return LeftAdditionalSidebarModule.initiateLoadingAnalytics;
  }

  get currentPeriod() {
    return LeftAdditionalSidebarModule.selectedPeriod;
  }
  get currentDimension() {
    return LeftAdditionalSidebarModule.currentDimension;
  }

  get currentMetric() {
    return LeftAdditionalSidebarModule.currentMetric;
  }

  handleAnalyticsReport() {
    const {
      currentDimension,
      currentPeriod,
      currentMetric,
      fromDateAs,
      toDateAs,
    } = this;
    const format = "YYYY-MM-DD";
    const formattedFromDate = moment(fromDateAs)
      .startOf("month")
      .format(format);
    const formattedToDate = moment(toDateAs).endOf("month").format(format);
    const dimensions = [currentDimension, currentPeriod].filter(Boolean);
    const params = {
      dimensions,
      metric: currentMetric,
      startDate: formattedFromDate,
      endDate: formattedToDate,
    };
    console.log(params);
    GoogleAnalyticsModule.getGoogleAnalyticsReport(params);
  }

  mounted() {
    this.handleAnalyticsReport();
  }

  @Watch("initiateLoading")
  onChangeLoadState(value: boolean) {
    if (value) {
      this.handleAnalyticsReport();
    }
  }
}
