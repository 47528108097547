<template>
  <BarChart :chart-data="chartData" :options="chartOptions" />
</template>

<script>
import { defineComponent } from "vue";
import { BarChart } from "vue-chart-3";
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  BarController,
  BarElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

export default defineComponent({
  components: {
    BarChart,
  },
  props: {
    eventsData: Array,
  },
  computed: {
    chartData() {
      const labels = this.eventsData.map((event) => event.eventName);
      const datasets = [
        {
          label: "Total Events",
          data: this.eventsData.map((event) =>
            event.dataPoints.reduce((acc, dp) => acc + dp.metric, 0)
          ),
          backgroundColor: this.eventsData.map((event) =>
            this.stringToColor(event.eventName)
          ),
        },
      ];
      return { labels, datasets };
    },
    chartOptions() {
      return {
        responsive: true,
        indexAxis: "y",
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "Event Count by Event Name",
          },
        },
        scales: {
          x: {
            beginAtZero: true,
          },
        },
      };
    },
  },
  methods: {
    stringToColor(str) {
      if (!str) return "#000";
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
      }
      return color;
    },
  },
});
</script>
