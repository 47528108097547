
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import datepicker from "vue3-datepicker";
import { LeftAdditionalSidebarModule } from "@/store/modules/left-additional-sidebar";
import { PeriodModule } from "@/store/modules/period.module";
import moment from "moment";

const resolves = ["Playlist", "Payments", "Payouts", "Analytics"];

@Options({
  name: "LeftAdditionalSidebar",
  components: { datepicker },
})
export default class LeftAdditionalSidebar extends Vue {
  // PROPS
  @Prop({ default: false })
  private absolute = false;

  // DATA
  private period = {
    to: null,
    from: null,
  };

  get momentData() {
    return moment().format("YYYY/MM");
  }

  get fromDate() {
    return new Date(PeriodModule.fromDate);
  }

  set fromDate(value: Date) {
    PeriodModule.SET_FROM_DATE(value);
  }

  get toDate() {
    return new Date(PeriodModule.toDate);
  }

  set toDate(value: Date) {
    PeriodModule.SET_TO_DATE(value);
  }

  get showContent() {
    return LeftAdditionalSidebarModule.showContent;
  }
  set showContent(value: boolean) {
    LeftAdditionalSidebarModule.SET_SHOW_CONTENT(value);
  }
  get contentReadyToShow() {
    return LeftAdditionalSidebarModule.contentReadyToShow;
  }
  get showButton() {
    return LeftAdditionalSidebarModule.showButton;
  }
  get showCooldown() {
    return LeftAdditionalSidebarModule.showCooldown;
  }
  get buttonPosition() {
    return LeftAdditionalSidebarModule.buttonPosition;
  }

  get showButtonEnhanced() {
    return LeftAdditionalSidebarModule.showButtonEnhanced;
  }

  get currentPeriod() {
    return LeftAdditionalSidebarModule.selectedPeriod;
  }
  set currentPeriod(value) {
    LeftAdditionalSidebarModule.SET_SELECTED_PERIOD(value);
  }

  get currentDimension() {
    return LeftAdditionalSidebarModule.currentDimension;
  }
  set currentDimension(value) {
    LeftAdditionalSidebarModule.SET_SELECTED_DIMENSION(value);
  }

  get currentMetric() {
    return LeftAdditionalSidebarModule.currentMetric;
  }
  set currentMetric(value) {
    LeftAdditionalSidebarModule.SET_SELECTED_METRIC(value);
  }

  // WATCH
  @Watch("showContent")
  isOpened(newValue: boolean) {
    if (newValue) {
      setTimeout(() => {
        LeftAdditionalSidebarModule.SET_CONTENT_READY_TO_SHOW(true);
      }, this.showCooldown);
    } else {
      LeftAdditionalSidebarModule.SET_CONTENT_READY_TO_SHOW(false);
    }
  }

  // METHODS
  handleDownloadReport(): void {
    LeftAdditionalSidebarModule.SET_INITIATE_LOADING(true);
    setTimeout(() => {
      LeftAdditionalSidebarModule.SET_INITIATE_LOADING(false);
    }, 300);
  }
  handleReportArtist(): void {
    LeftAdditionalSidebarModule.SET_INITIATE_LOADING_ARTIST(true);
    setTimeout(() => {
      LeftAdditionalSidebarModule.SET_INITIATE_LOADING_ARTIST(false);
    }, 300);
  }
  handleEnhancedDownloadReport(): void {
    LeftAdditionalSidebarModule.SET_INITIATE_LOADING_ENHENCED(true);
    setTimeout(() => {
      LeftAdditionalSidebarModule.SET_INITIATE_LOADING_ENHENCED(false);
    }, 300);
  }
  handleReportAnalytics(): void {
    LeftAdditionalSidebarModule.SET_INITIATE_LOADING_ANALYTICS(true);
    setTimeout(() => {
      LeftAdditionalSidebarModule.SET_INITIATE_LOADING_ANALYTICS(false);
      this.showContent = !this.showContent;
    }, 300);
  }

  // MOUNTED
  mounted() {
    if (resolves.join("").includes(this.$route.name as string)) {
      LeftAdditionalSidebarModule.SET_SHOW_BUTTON(true);
    }
    this.$router.beforeEach((to, from, next) => {
      if (to.name == "Payments" || to.name == "Payouts") {
        LeftAdditionalSidebarModule.SET_SHOW_BUTTON_ENHANCED(false);
      } else {
        LeftAdditionalSidebarModule.SET_SHOW_BUTTON_ENHANCED(true);
      }
      if (resolves.join("").includes(to.name as string)) {
        LeftAdditionalSidebarModule.SET_SHOW_BUTTON(true);
      } else {
        LeftAdditionalSidebarModule.SET_SHOW_BUTTON(false);
      }
      next();
    });

    // Нет необходимости использовать remove event listener т.к. компонент находится в главном лейауте
    // Если возникнет необходимость в удалении прослушивателя - обязательно навесить
    window.addEventListener("scroll", (event) => {
      const offset = window.pageYOffset;
      const element = this.$refs.sidebar as HTMLElement;
      if (offset > 0 && offset < 60) {
        element.style.top = `${60 - offset}px`;
      } else if (offset >= 60) {
        element.style.top = `${0}px`;
      }
    });
  }
}
