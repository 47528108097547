import swal from "sweetalert2";
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from "vuex-module-decorators";
import store from "@/store";
import { fetchGoogleAnalyticsReport } from "@/api/google-analytics";

export interface IDataPoint {
  period: string;
  metric: number;
}

export interface IEventData {
  eventName: string;
  dataPoints: IDataPoint[];
}

export interface IGoogleAnalyticsState {
  list: IEventData[];
}

@Module({ dynamic: true, store, name: "google-analytics", namespaced: true })
class GoogleAnalytics extends VuexModule implements IGoogleAnalyticsState {
  public list: IEventData[] = [];

  @Mutation
  private SET_LIST(data: any[]) {
    const eventMap: Record<string, IEventData> = {};

    data.forEach((entry) => {
      const eventName = entry.dimensionValues[0].value;
      const period =
        entry.dimensionValues.length > 1
          ? entry.dimensionValues[1].value
          : "total";
      const metric = parseInt(entry.metricValues[0].value, 10);

      if (!eventMap[eventName]) {
        eventMap[eventName] = { eventName, dataPoints: [] };
      }

      eventMap[eventName].dataPoints.push({ period, metric });
    });
    for (const eventName in eventMap) {
      eventMap[eventName].dataPoints.sort((a, b) =>
        a.period.localeCompare(b.period)
      );
    }
    this.list = Object.values(eventMap);
  }

  @Action
  public async getGoogleAnalyticsReport({
    dimensions,
    metric,
    startDate,
    endDate,
  }: {
    dimensions: string[];
    metric: string;
    startDate: string;
    endDate: string;
  }) {
    try {
      swal.showLoading();
      const { data } = await fetchGoogleAnalyticsReport({
        dimensions,
        metric,
        startDate,
        endDate,
      });
      if (data) {
        this.SET_LIST(data);
      }
      return data;
    } catch (e) {
      console.log(e);
    } finally {
      swal.close();
    }
  }
}

export const GoogleAnalyticsModule = getModule(GoogleAnalytics);
